import "@fortawesome/fontawesome-free/css/all.min.css";
import store from './store/index'

import { createApp } from 'vue'
const app = createApp(App)


import App from './App.vue'
import router from './router'


import Pagination from 'v-pagination-3';
app.component('pagination', Pagination, {
    options: {
        'chunk': 4,
        'theme': 'bulma'
    }
});

app.config.globalProperties.$filters = {
    // Round values
    round(value) {
        if (!value) {
            value = 0
        }

        let decimals = 2

        value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
        return value
    },
}

app.use(router)
app.use(store)


app.mount('#app')
