<template>
  <div>
<!--    <sidebar />-->

    <!-- Dashboard Section start -->
    <section class="dashboard-section body-collapse transactions">
        <div class="overlay pt-120">
            <div class="container-fruid">
                <div class="head-area">
                    <div class="row">
                        <div class="col-lg-5 col-md-4">
                            <h4>Trades</h4>
                        </div>
                        <div class="col-lg-7 col-md-8">
                            <div class="transactions-right d-flex align-items-center">
                                <form action="#" class="flex-fill">
                                    <div class="form-group d-flex align-items-center">
                                        <img src="../../../public/assets/dashboard/images/icon/search.png" alt="icon">
                                        <input type="text" placeholder="Type to search...">
                                    </div>
                                </form>
<!--                                <a href="javascript:void(0)">Monthly Statement</a>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12">
                        <div class="transactions-main">
                            <div class="top-items">
                                <h6>All Trades</h6>
                                <div class="export-area">
                                    <ul class="d-flex align-items-center">
<!--                                        <li><a href="javascript:void(0)"><img src="../../../public/assets/dashboard/images/icon/printer.png" alt="icon">Print</a></li>-->
<!--                                        <li><a href="javascript:void(0)"><img src="../../../public/assets/dashboard/images/icon/excel.png" alt="icon">Excel</a></li>-->
<!--                                        <li><a href="javascript:void(0)"><img src="../../../public/assets/dashboard/images/icon/pdf.png" alt="icon">PDF</a></li>-->
                                        <li>
                                          <a class="btn btn-success" @click="$router.push('/admin/new/trade')">
                                            New Trade
                                          </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th style="text-align: left; width: 15%">Client Name</th>
                                      <th style="text-align: center">Currency</th>
                                      <th style="text-align: left">Liquidity Provider</th>
                                      <th style="text-align: left">Client Rate</th>
                                      <th style="text-align: left">Counter Volume</th>
                                      <th style="text-align: left">Liquidity Rate</th>
                                      <th style="text-align: center">Trade Date</th>
                                      <th style="text-align: left">Profit</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="trade in trades" v-bind:key="trade.id">
                                      <td style="text-align: left; width: 15%">{{ trade.client.name }}</td>
                                      <td style="text-align: center">{{ trade.base_currency.initials }}</td>
                                      <td style="text-align: left">{{ trade.liquidity_provider.name }}</td>
                                      <td style="text-align: right">{{ trade.c_rate }}</td>
                                      <td style="text-align: right">{{ trade.local_volume }}</td>
                                      <td style="text-align: right">{{ trade.l_rate }}</td>
                                      <td style="text-align: center">{{ trade.created_at }}</td>
                                      <td style="text-align: right">{{ trade.profit }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                            </div>
                            <nav aria-label="Page navigation" class="d-flex justify-content-center mt-40">
                                <ul class="pagination justify-content-center align-items-center mb-40">
                                    <li class="page-item">
                                        <a class="page-link previous" href="javascript:void(0)" aria-label="Previous">
                                            <i class="fa-solid fa-angles-left"></i>
                                        </a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link previous" href="javascript:void(0)" aria-label="Previous">
                                            <i class="fa-solid fa-angle-left"></i>
                                        </a>
                                    </li>
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)">1</a></li>
                                    <li class="page-item"><a class="page-link active" href="javascript:void(0)">2</a></li>
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)">...</a></li>
                                    <li class="page-item">
                                        <a class="page-link next" href="javascript:void(0)" aria-label="Next">
                                            <i class="fa-solid fa-angle-right"></i>
                                        </a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link next" href="javascript:void(0)" aria-label="Next">
                                            <i class="fa-solid fa-angles-right"></i>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Dashboard Section end -->

  </div>
</template>
<script>
import core_function from "@/services/core_function";

export default {
  name: "admin-layout",
  data() {
    return {
      trades: [],
    };
  },
  components: {
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    async getTrades(){
      try{
        let response = await core_function.loadTrades()
        this.trades = response.data
      }catch (err){
        console.log(err)
      }
    }
  },
  mounted() {
    this.getTrades()
  }
};
</script>

<style scoped>
.pagination .page-item:hover, .pagination .page-item.active, .pagination .page-link:hover, .pagination .page-link.active {
    background-color: green;
    color: var(--bs-white);
}
</style>