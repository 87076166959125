import { createRouter, createWebHistory } from 'vue-router'
import Index from "@/views/HomeView.vue";
import About from "@/views/AboutView.vue";
import Services from "@/views/ServicesView.vue";
import LogIn from "@/views/auth/LogIn.vue";
import Auth from "@/layouts/Auth.vue";
import Dashboard from "@/views/admin/Dashboard.vue";
import Admin from "@/layouts/Admin.vue";
import store from "@/store/index";
import Clients from "@/views/admin/Clients.vue";
import Trades from "@/views/admin/Trades.vue";
import NewTrade from "@/views/admin/NewTrade.vue";
import NewClient from "@/views/admin/NewClient.vue";
import Currencies from "@/views/admin/Currencies.vue";
import NewCurrency from "@/views/admin/NewCurrency.vue";



const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: Index,
      meta: {requiresAuth: false},
    },
    {
      path: "/about",
      component: About,
      meta: {requiresAuth: false},
    },
    {
      path: "/services",
      component: Services,
      meta: {requiresAuth: false},
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/AboutView.vue')
    },
    {
      path: "/login",
      component: LogIn,
      meta: {requiresAuth: false},
    },
    {
      path: "/admin",
      component: Admin,
      meta: {requiresAuth: true},
      children: [
        {
          path: "/admin/dashboard",
          component: Dashboard,
        },
        {
          path:"/admin/new/trade",
          component: NewTrade,
        },
        {
          path:"/admin/new/client",
          component: NewClient,
        },
        {
          path:"/admin/new/currency",
          component: NewCurrency,
        },
        {
          path:"/admin/trades",
          component: Trades,
        },
        {
          path:"/admin/clients",
          component: Clients,
        },
        {
          path:"/admin/currencies",
          component: Currencies,
        },
      ],
    },

    // { path: "/dashboard/", component: DashboardPage, meta: {
    //   requiresAuth: true
    // } },
    // { path: "/register/client", component: registerClient, meta: {
    //   requiresAuth: true
    // } },
    // { path: "/clients", component: allClients, meta: {
    //   requiresAuth: true
    // } },
    // { path: "/trades", component: allTrades, meta: {
    //   requiresAuth: true
    // } },
    // { path: "/record/trade", component: newTrade, meta: {
    //   requiresAuth: true
    // } },
    // { path: "/preview/trade", component: previewTrade, meta: {
    //   requiresAuth: true
    // } },
    { path: "/:pathMatch(.*)*", redirect: "/" },
  ]
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log("The Auth Status Was")
    console.log(store.state.Auth.authStatus)
    if (store.state.Auth.authStatus !== 2 || store.state.Auth.authStatus === 'undefined') {
      next({ path: '/login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router;
