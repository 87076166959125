<template>
  <!-- start preloader -->
<!--    <div class="preloader" id="preloader"></div>-->
    <!-- end preloader -->

    <!-- Scroll To Top Start-->
    <a href="javascript:void(0)" class="scrollToTop"><i class="fas fa-angle-double-up"></i></a>
    <!-- Scroll To Top End -->

    <!-- header-section start -->
    <header class="header-section">
        <div class="overlay">
            <div class="container">
                <div class="row d-flex header-area">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <a class="navbar-brand" href="index.html">
                            <img src="assets/images/logo.png" style="max-width: 80px;" class="logo" alt="logo">
                        </a>
                        <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbar-content">
                            <i class="fas fa-bars"></i>
                        </button>
                        <div class="collapse navbar-collapse justify-content-end" id="navbar-content">
                            <ul class="navbar-nav mr-auto mb-2 mb-lg-0">

                                <li class="nav-item main-navbar">
                                    <router-link class="nav-link" to="/">Home</router-link>
                                </li>

                                <li class="nav-item main-navbar">
                                    <a class="nav-link" href="javascript:void(0)">About</a>
                                </li>

                                <li class="nav-item main-navbar">
                                    <a class="nav-link" href="javascript:void(0)">Services</a>
                                </li>

                                <li class="nav-item main-navbar">
                                    <a class="nav-link" href="javascript:void(0)">Contact</a>
                                </li>

                                <li class="nav-item main-navbar">
                                  <router-link class="nav-link" :to="'/login'">Login</router-link>
                                </li>

                            </ul>

                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>
    <!-- header-section end -->

    <!-- Login Reg start -->
    <section class="login-reg">
        <div class="overlay pt-120">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-xl-6 order-xl-0 order-1">
                        <div class="sec-img d-rtl">
                            <img src="../../../public/assets/images/login-reg-bg.png" class="max-un" alt="image">
                        </div>
                    </div>
                    <div class="col-xl-5">
                        <div class="section-text text-center">
                            <!-- <h5 class="sub-title">Account</h5> -->
                            <h2 class="title">Log in to Continue</h2>
<!--                            <p class="dont-acc">Don’t have an account? <a href="sign-up.html">Sign up</a></p>-->
<!--                            <div class="reg-google">-->
<!--                                <a href="javascript:void(0)"><i class="fab fa-google"></i>Log in with Google</a>-->
<!--                            </div>-->
<!--                            <span class="or">Or Continue With</span>-->
                        </div>
                        <form @submit.prevent="login()">
                            <div class="row">
                                <div class="col-12">
                                    <div class="single-input">
                                        <label for="logemail">Your Email</label>
                                        <input type="text" id="logemail" v-model="formData.username" placeholder="Enter Your Email">
                                    </div>
                                    <div class="single-input">
                                        <label for="logpassword">Your Password</label>
                                        <input type="password" id="logpassword" v-model="formData.password" placeholder="Enter Your Password">
                                    </div>
                                    <button type="submit" class="cmn-btn w-100">Login</button>
                                </div>
                            </div>
                        </form>
                        <div class="forgot-pass mt-30 text-center">
                            <a href="javascript:void(0)">Forgot Password</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Login Reg end -->

</template>
<script>
import '../../../public/assets/css/bootstrap.min.css'
import '../../../public/assets/css/fontawesome.min.css'
import '../../../public/assets/css/jquery-ui.css'
import '../../../public/assets/css/nice-select.css'
import '../../../public/assets/css/magnific-popup.css'
import '../../../public/assets/css/slick.css'
import '../../../public/assets/css/arafat-font.css'
import '../../../public/assets/css/animate.css'
import '../../../public/assets/css/style.css'


import '../../../public/assets/js/home_main.js'

import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      formData: {
        username: '',
        password: '',
      },
    };
  },
  computed: {
        ...mapGetters('Auth',['authUser', 'authStatus', 'authError']),
        // ...mapGetters('User', ['userType']),
    },
  methods: {
    async login(){
        this.loading = true
        // const { username, password } = this
        this.$store.dispatch('Auth/login', this.formData)
        .then(() => {
            if(this.authStatus === 2) {
                this.$store.dispatch('User/getUser').then(() => {})
                if (this.authUser.last_login.length === 0) {
                    this.$router.push('/update-password/ft');
                    return false
                }

                if (this.authUser.user_type === 'admin') {
                    this.$router.push('/admin/dashboard');
                } else {
                    this.$router.push('/admin/dashboard');
                }
                console.log("go to clients")

            }else if (this.authStatus === 3) {
                this.loading = !this.loading
            } else {
                this.loading = !this.loading
            }
        })
    },
  },
};
</script>

<style scoped>
.cmn-btn{
  background: green;
}

.header-section {
    margin-left: 0px !important;
    width: 100% !important;
    border-bottom: none;
    box-shadow: 0px 1px 4px grey;
}
.header-section .navbar {
    padding: 8px 15px;
}
input {
    font-family: 'Jost' !important;
}

</style>
