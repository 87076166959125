import api from '../api/api'


const auth = {
  login(content) {
    return new Promise((resolve, reject) => {
      api.post('/auth/token/', content, (data) => {
        resolve(data);
      }, (error) => {
        reject(error);
      });
    });
  },
  verifyTwoFA(content) {
      return new Promise((resolve, reject) => {
          api.post(`/auth/tfa/verify/`, content, (data) => {
              resolve(data);
          }, (error) => {
              reject(error);
          });
      });
  },
  resetPassword(content) {
    return new Promise((resolve, reject) => {
      api.post('/auth/reset_password_request/', content, (data) => {
        resolve(data);
      }, (error) => {
        reject(error);
      });
    });
  },
  setPassword(content) {
    return new Promise((resolve, reject) => {
      api.post('/auth/confirm/', content, (data) => {
        resolve(data);
      }, (error) => {
        reject(error);
      });
    });
  }
}


export default auth