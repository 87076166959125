import axios from 'axios';
import Cookies from 'js-cookie'
import store from '../store/index'
import router from '../router'
import NProgress from 'vue-nprogress'
const nprogress = new NProgress()

export const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  timeout: 60000,
  headers: {
    'Accept': 'application/json',
    // 'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use((config) => {
  nprogress.start()
  store.dispatch('Loading/updateLoading', true)
  const accessToken = Cookies.get('durrafx_token');
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
}, function (error) {
  nprogress.done()
  store.dispatch('Loading/updateLoading', false)
  return Promise.reject(error)
});

// before a response is returned stop nprogress
instance.interceptors.response.use(response => {
  nprogress.done()
  store.dispatch('Loading/updateLoading', false)
  return response
}, function (error) {
  nprogress.done()
  store.dispatch('Loading/updateLoading', false)
  console.log(error.response.config.url)
  if (error.response.status === 401 && error.response.config.url !== '/auth/token/') {
    window.toast.fire({
      icon: 'error',
      title: 'Session timed out. Please login to continue.'
    })
    store.dispatch('Auth/logout')
    store.dispatch('User/logout')
    localStorage.setItem('returnUrl', router.currentRoute.path)
    router.push('/login')
  }
  // alert(error)
  return Promise.reject(error)
})

const api = {
  request(method, url, data, successMsg = null, errorMsg = null) {
    instance.request({
      url,
      data,
      method: method.toLowerCase(),
    }).then(successMsg).catch(errorMsg);
  },

  get(url, success = null, errorMsg = null) {
    return this.request('get', url, {}, success, errorMsg);
  },

  post(url, data, success = null, errorMsg = null) {
    return this.request('post', url, data, success, errorMsg);
  },

  put(url, data, success = null, errorMsg = null) {
    return this.request('put', url, data, success, errorMsg);
  },

  patch(url, data, success = null, errorMsg = null) {
    return this.request('patch', url, data, success, errorMsg);
  },

  delete(url, data = {}, success = null, errorMsg = null) {
    return this.request('delete', url, data, success, errorMsg);
  },
};

export default api;
