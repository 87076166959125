<template>
  <div>
<!--    <sidebar />-->

    <!-- Dashboard Section start -->
    <section class="dashboard-section body-collapse transactions">
        <div class="overlay pt-120">
            <div class="container-fruid">
                <div class="head-area">
                    <div class="row">
                        <div class="col-lg-5 col-md-4">
                            <h4>Clients</h4>
                        </div>
                        <div class="col-lg-7 col-md-8">
<!--                            <div class="transactions-right d-flex align-items-center">-->
<!--                                <form action="#" class="flex-fill">-->
<!--                                    <div class="form-group d-flex align-items-center">-->
<!--                                        <img src="../../../public/assets/dashboard/images/icon/search.png" alt="icon">-->
<!--                                        <input type="text" placeholder="Type to search...">-->
<!--                                    </div>-->
<!--                                </form>-->
<!--&lt;!&ndash;                                <a href="javascript:void(0)">Monthly Statement</a>&ndash;&gt;-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12">
                        <div class="transactions-main">
                            <div class="top-items">
                                <h6>New Client</h6>
                                <div class="export-area">
                                    <ul class="d-flex align-items-center">

                                    </ul>
                                </div>
                            </div>

<!--                            <div class="table-responsive">-->
                              <form class="form" @submit.prevent="postClient()">
                              <div class="row">
                                  <div class="col-sm-6 pl-2">
                                    <div class="form-group">
                                      <label class="form-label">Business Identification Number</label>
                                      <input type="text"
                                             v-model="form.business_identification_number" class="form-control">
                                    </div>

                                    <div class="form-group">
                                      <label class="form-label">Country</label>
                                      <input type="text" v-model="form.country" class="form-control">
                                    </div>

                                    <div class="form-group">
                                      <label class="form-label">Client Type</label>
                                      <select v-model="form.client_type" class="form-control">
                                        <option disabled value="">Please select type</option>
                                        <option value="Supplier">Supplier</option>
                                        <option value="Liquidity Provider">Liquidity Provider</option>
                                      </select>
                                    </div>

                                  </div>

                                  <div class="col-sm-6 pr-2">
                                    <div class="form-group">
                                      <label class="form-label">Business Name</label>
                                      <input type="text" v-model="form.name" class="form-control">
                                    </div>

                                    <div class="form-group">
                                      <label class="form-label">Status</label>
                                      <select v-model="form.status" class="form-control">
                                        <option disabled value="">Please select status</option>
                                        <option>Active</option>
                                        <option>Pending KCV</option>
                                        <option>Deactivated</option>
                                      </select>
                                    </div>

                                    <div class="form-group">
                                      <label class="form-label">Address</label>
                                      <input type="text" v-model="form.address" class="form-control"/>
                                    </div>

                                    <div class="form-group">
                                      <label class="form-label">Contact Person</label>
                                      <input type="text" v-model="form.key_person" class="form-control"/>
                                    </div>

                                    <br>
                                    <div class="form-group">
                                      <button class="btn btn-success" type="submit">Submit</button>
                                    </div>
                                  </div>
                              </div>
                                </form>
<!--                            </div>-->

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Dashboard Section end -->
  </div>
</template>
<script>

import core_function from "@/services/core_function";
export default {
  data(){
    return{
      form: {},
      clients: [],
      currencies: [],
    }
  },
  components: {

  },
  methods: {
    async postClient(){
      try{
        let response = await core_function.postClient(this.form)
        if(response.status === 201){
          this.$router.push("/admin/clients")
        }else{
          console.log(response)
        }
      }catch(err){
        console.log(err)
      }
    }
  },
  mounted() {

  }
};
</script>
