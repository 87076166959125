import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

import modules from './modules'

const store = createStore({
  modules,
  plugins: [createPersistedState()],
})

export default store;



// const store = createStore({
//   state: {
//     token: null,
//     user: null,
//
//
//     clients: [],
//
//     trades: [],
//
//
//
//   },
  // mutations: {
  //   clearToken(context) {
  //     context.commit("removeToken");
  //   },
  //   clearUser(context) {
  //     context.commit("removeUser");
  //   },
  //   addToken(context) {
  //     context.commit("setToken");
  //   },
  //
  //
  //   setClients(state, clients){
  //     state.clients=clients
  //   },
  //   setTrades(state, trades){
  //     state.trades=trades
  //   }
  //
  //
  //
  //
  //
  // },
  // actions: {
  //   clearToken(context) {
  //     context.commit("removeToken");
  //   },
  //   clearUser(context) {
  //     context.commit("removeUser");
  //   },
  //   addToken(context) {
  //     context.commit("setToken");
  //   },


    // async LoadClients(context) {
    //   const url = `https://api.roberms.com/durra/get/clients`;
    //
    //   const response = await fetch(url, {
    //     mode: "cors",
    //     // credentials: "include",
    //     headers: {
    //       "Access-Control-Allow-Origin": true,
    //     },
    //   });
    //   const responseData = await response.json();
    //   if (!response.ok) {
    //     //
    //   }
    //   const clients = [];
    //   for (const key in responseData) {
    //     const client = {
    //       id: key,
    //       Business_id: responseData[key].Business_id,
    //       Business_name: responseData[key].Business_name,
    //
    //       country: responseData[key].country,
    //       status: responseData[key].status,
    //       client_type: responseData[key].client_type,
    //       address: responseData[key].address,
    //       memberCourt: responseData[key].memberCourt,
    //       key_person: responseData[key].key_person,
    //       Registration_date: responseData[key].Registration_date,
    //
    //
    //
    //       processed: responseData[key].processed,
    //     };
    //     clients.push(client);
    //   }
    //   context.commit("setClients", clients);
    // },

    // async LoadTrades(context) {
    //   const url = `https://api.roberms.com/durra/get/trades`;
    //
    //   const response = await fetch(url, {
    //     mode: "cors",
    //     // credentials: "include",
    //     headers: {
    //       "Access-Control-Allow-Origin": true,
    //     },
    //   });
    //   const responseData = await response.json();
    //   if (!response.ok) {
    //     //
    //   }
    //   const trades = [];
    //   for (const key in responseData) {
    //     const trade = {
    //       id: key,
    //       base_currency: responseData[key].base_currency,
    //       counter_currency: responseData[key].counter_currency,
    //
    //       client: responseData[key].client,
    //       status: responseData[key].status,
    //       client_type: responseData[key].client_type,
    //       liquidity_provider: responseData[key].liquidity_provider,
    //       c_rate: responseData[key].c_rate,
    //       base_volume: responseData[key].base_volume,
    //       local_volume: responseData[key].local_volume,
    //       l_rate: responseData[key].l_rate,
    //       notes: responseData[key].notes,
    //       trade_date: responseData[key].trade_date,
    //       profit: responseData[key].profit,
    //
    //       processed: responseData[key].processed,
    //     };
    //     trades.push(trade);
    //   }
    //   context.commit("setTrades", trades);
    // },
  // },
  // getters: {
  //   isLoggedIn() {
  //     return !!localStorage.getItem("token");
  //   },
  //
  //
  //
  //   clients: (state) => state.clients,
  //   trades: (state) => state.trades,
  //
  //
  //
  //   voterGetter: (state)=>(vid)=>state.voters.filter(vnumber=>vnumber.id_number==vid),
  //
  // },


  //
// });