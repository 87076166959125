<template>
  <div>
    <!-- start preloader -->
<!--    <div class="preloader" id="preloader"></div>-->
    <!-- end preloader -->

    <!-- Scroll To Top Start-->
    <a href="javascript:void(0)" class="scrollToTop"><i class="fas fa-angle-double-up"></i></a>
    <!-- Scroll To Top End -->

    <!-- header-section start -->
    <header class="header-section">
        <div class="overlay">
            <div class="container">
                <div class="row d-flex header-area">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <a class="navbar-brand" href="index.html">
                            <img src="../../public/assets/images/logo.png" style="max-width: 80px;" class="logo" alt="logo">
                        </a>
                        <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbar-content">
                            <i class="fas fa-bars"></i>
                        </button>
                        <div class="collapse navbar-collapse justify-content-end" id="navbar-content">
                            <ul class="navbar-nav mr-auto mb-2 mb-lg-0">

                                <li class="nav-item main-navbar">
                                    <router-link class="nav-link" to="/">Home</router-link>
                                </li>

                                <li class="nav-item main-navbar">
                                    <a class="nav-link" href="javascript:void(0)">About</a>
                                </li>

                                <li class="nav-item main-navbar">
                                    <a class="nav-link" href="javascript:void(0)">Services</a>
                                </li>

                                <li class="nav-item main-navbar">
                                    <a class="nav-link" href="javascript:void(0)">Contact</a>
                                </li>

                                <li class="nav-item main-navbar">
                                  <router-link class="nav-link" :to="'/login'">Login</router-link>
                                </li>

                            </ul>

                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>
    <!-- header-section end -->

    <!-- Banner Section start -->
    <section class="banner-section index">
        <div class="overlay">
            <div class="shape-area">
<!--                <img src="assets/images/banner-box.png" class="obj-1" alt="image">-->
<!--                <img src="assets/images/banner-human.png" class="obj-2" alt="image">-->
<!--                <img src="assets/images/banner-rocket.png" class="obj-3" alt="image">-->
<!--                <img src="assets/images/banner-clock.png" class="obj-4" alt="image">-->
            </div>
<!--          style="background-image: url('https://mrwallpaper.com/images/hd/digital-art-depicting-forex-trading-njy699ski53a0cbe.jpg')"-->
            <div class="banner-content" >
                <div class="container wow fadeInUp">
                    <div class="content-shape">
                        <img src="assets/images/banner-wallet.png" class="obj-1" alt="image">
                    </div>
                    <div class="row justify-content-between align-items-center">
                        <div class="col-lg-5 col-md-6">
                            <div class="main-content">
                                <div class="top-area section-text">
                                    <h5 class="sub-title">Access Deep Liquidity in Africa</h5>
                                    <h1 class="title">Better than the Bank Wire</h1>
                                    <p class="xlr">DurraFX offers reliable, real-time access to liquidity for International Money Transfer
                                        Operators (IMTOs), collection merchants, and FX brokers across Africa. Our solutions are built for speed,
                                        transparency, and efficiency, providing unparalleled access to local currencies and cross-border settlement
                                        faster than traditional banks.</p>
                                </div>
                                <div class="bottom-area d-xxl-flex">
                                    <a href="sign-up.html" class="cmn-btn">Open a Free Account</a>
                                    <a class="cmn-btn active mfp-iframe popupvideo" href="">See How it Works</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-5 col-md-6">

                            <div class="send-money">
                                <form action="#">
                                    <div class="currency-area">
                                        <div class="left-side">
                                            <span class="mdr">You send</span>
                                            <input type="text" class="xlr" placeholder="Enter Amount" v-model="form.currencyToSend">
                                        </div>
                                        <div class="right-side">
                                            <select class="form-control" v-model="form.selectedCurrency"
                                              style="display: block !important;">
                                                <option v-for="currency in currencies" v-bind:key="currency.id"
                                                        :value="currency"
                                                  >
                                                  {{ currency.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="calculation">
                                        <div class="head-area">
                                            <img src="../../public/assets/dashboard/images/icon/conversion.png" alt="image">
                                            <span class="mdr highlight">Show calculation</span>
                                        </div>
                                        <div class="calculation-details">
                                            <div class="single-area d-flex align-items-center">
                                                <div class="left-area">
                                                    <i class="fas fa-minus"></i>
                                                    <span class="mdr">{{ $filters.round((form.currencyToSend / form.selectedCurrency.conversion_factor) * form.fees/100) }} USD</span>
                                                </div>
                                                <div class="right-area">
                                                    <span class="mdr">Our fee</span>
                                                </div>
                                            </div>
                                            <div class="single-area d-flex align-items-center">
                                                <div class="left-area">
                                                    <i class="fas fa-equals"></i>
                                                    <span class="mdr">{{ form.currencyToSend }} {{ form.selectedCurrency.initials }}</span>
                                                </div>
                                                <div class="right-area">
                                                    <span class="mdr">We’ll Convert</span>
                                                </div>
                                            </div>
                                            <div class="single-area d-flex align-items-center">
                                                <div class="left-area">
                                                    <i class="fas fa-times"></i>
                                                    <span class="mdr" v-if="form.selectedCurrency != null">{{ $filters.round(form.selectedCurrency.conversion_factor) }}</span>
                                                </div>
                                                <div class="right-area">
                                                    <span class="mdr highlight">DurraFX Rate</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="currency-area">
                                        <div class="left-side">
                                            <span class="mdr">Recipient gets</span>
                                            <h5>{{ $filters.round(form.currencyToSend / form.selectedCurrency.conversion_factor - ((form.currencyToSend / form.selectedCurrency.conversion_factor) * form.fees/100)) }}</h5>
                                        </div>
                                        <div class="right-side recipient">
                                            <select>
                                                <!-- <option value="1">AUS</option> -->
                                                <option value="2">USD</option>
                                                <!-- <option value="3">GBP</option> -->
                                            </select>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-40">
                                        <a href="javascript:void(0)" class="cmn-btn w-100">Get Started</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="counter-section">
            <div class="container wow fadeInUp">
                <div class="row cus-mar">
                    <div class="col-xl-3 col-md-3 col-sm-6">
                        <div class="single-area d-flex align-items-center justify-content-center">
                            <div class="text-area text-center">
                                <h2><span class="counter">10</span><span>+</span></h2>
                                <p>Supported Currencies</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-3 col-sm-6">
                        <div class="single-area d-flex align-items-center justify-content-center">
                            <div class="text-area text-center">
                                <h2><span class="counter">10</span><span>+</span></h2>
                                <p>Available Countries</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-3 col-sm-6">
                        <div class="single-area d-flex align-items-center justify-content-center">
                            <div class="text-area text-center">
                                <h2><span class="counter">7</span><span>+</span></h2>
                                <p>Payment Methods</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-3 col-sm-6">
                        <div class="single-area d-flex align-items-center justify-content-center">
                            <div class="text-area text-center">
                                <h2><span class="counter">7</span><span>/</span><span class="counter">24</span><span>+</span></h2>
                                <p>Support Team</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Banner Section end -->

    <!-- Global Payment start -->
    <section class="global-payment">
        <div class="overlay pt-120 pb-120">
            <div class="container wow fadeInUp">
                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <div class="section-header text-center">
                            <h5 class="sub-title">Send money in a heartbeat</h5>
                            <h2 class="title">The World At Your Fingertips</h2>
                            <p>Sign up to start saving on international money transfers and currency exchange.</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xxl-6 col-xl-5 order-xl-0 order-1">
                        <div class="image-area d-rtl left-side">
                            <img src="../../public/assets/images/global-payment-img.png" alt="images" class="max-un">
                        </div>
                    </div>
                    <div class="col-xxl-6 col-xl-7">
                        <div class="row cus-mar">
                            <div class="col-sm-6 col-6">
                                <div class="single-item">
                                    <img src="../../public/assets/images/global-payment-icon-1.png" alt="icon">
                                    <h5>Peace of Mind</h5>
                                    <p>You dont have to struggle following how your money is handled. We do it for you.</p>
                                </div>
                            </div>
                            <div class="col-sm-6 col-6">
                                <div class="single-item">
                                    <img src="../../public/assets/images/global-payment-icon-2.png" alt="icon">
                                    <h5>Business-Ready</h5>
                                    <p>Make payments and receive money from your clients any time.</p>
                                </div>
                            </div>
                            <div class="col-sm-6 col-6">
                                <div class="single-item">
                                    <img src="../../public/assets/images/global-payment-icon-3.png" alt="icon">
                                    <h5>100% Transparent</h5>
                                    <p>Our fee and costs are available to see what you will be charged without any hidden costs.</p>
                                </div>
                            </div>
                            <div class="col-sm-6 col-6">
                                <div class="single-item">
                                    <img src="../../public/assets/images/global-payment-icon-4.png" alt="icon">
                                    <h5>International Network</h5>
                                    <p>With our partiners in amny countries, be assured to to tranasct with clients all over the world in our platform.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Global Payment end -->

    <!-- Our Solutions start -->
    <section class="our-solutions">
        <div class="overlay pt-120 pb-120">
            <div class="container wow fadeInUp">
                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <div class="section-header dark-sec text-center">
                            <h5 class="sub-title">High speeds. Low fees. No hassle.</h5>
                            <h2 style="color: var(--para-color)" class="title">All Your Payments In One Place</h2>
                            <p style="color: var(--para-color)">Get used to low fees and great exchange rates on international money transfers.Expand your business worldwide</p>
                        </div>
                    </div>
                </div>
                <div class="row cus-mar">
                    <div class="col-xl-3 col-6">
                        <div class="single-item">
                            <img src="../../public/assets/images/our-solutions-icon-1.png" alt="icon">
                            <h5>Liquidity Provision for IMTOs</h5>
                            <p>We offer robust local liquidity for International Money Transfer Operator.</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-6">
                        <div class="single-item">
                            <img src="../../public/assets/images/our-solutions-icon-2.png" alt="icon">
                            <h5>Collections</h5>
                            <p>We help companies collect from their merchants and customers. If you are internationa organization lets help you get paid by your customers.</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-6">
                        <div class="single-item">
                            <img src="../../public/assets/images/our-solutions-icon-3.png" alt="icon">
                            <h5>Conversions</h5>
                            <p>We have a wide range of curencies  at competitive rates for seamless currency exchanges in African markets with same-day settlements .</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-6">
                        <div class="single-item">
                            <img src="../../public/assets/images/our-solutions-icon-4.png" alt="icon">
                            <h5>Global Account</h5>
                            <p>Get one account that you can use in all your global paayments.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Our Solutions end -->

    <!-- How it Works start -->
    <section class="how-it-works">
        <div class="overlay pb-120">
            <div class="container wow fadeInUp">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="section-header text-center">
                            <h5 class="sub-title">How it works?</h5>
                            <h2 class="title">Just few steps to start</h2>
                            <p>It's easier than you think. Follow 3 simple easy steps</p>
                        </div>
                    </div>
                </div>
                <div class="row cus-mar">
                    <div class="col-xl-3 col-sm-6 col-6">
                        <div class="single-item first text-center">
                            <img src="../../public/assets/images/how-works-icon-1.png" alt="icon">
                            <h5>Register for free</h5>
                            <p>Simply sign up online for free and verify your identity</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 col-6">
                        <div class="single-item second text-center">
                            <img src="../../public/assets/images/how-works-icon-2.png" alt="icon">
                            <h5>Set up your transfer</h5>
                            <p>Add a recipient's details and choose which currency ...</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 col-6">
                        <div class="single-item first text-center">
                            <img src="../../public/assets/images/how-works-icon-3.png" alt="icon">
                            <h5>Make your payment</h5>
                            <p>Send us your funds with a bank transfer and we'll notify..</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 col-6">
                        <div class="single-item text-center">
                            <img src="../../public/assets/images/how-works-icon-4.png" alt="icon">
                            <h5>You're all done!</h5>
                            <p>We inform you when the money has been sent and can also ...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- How it Works end -->

    <!-- FAQs In start -->
    <section class="faqs-section">
        <div class="overlay pt-120 pb-120">
            <div class="container">
                <div class="row d-flex justify-content-center">
                    <div class="col-lg-7">
                        <div class="section-header text-center">
                            <h5 class="sub-title">Frequently Asked Questions</h5>
                            <h2 class="title">If you got questions we have answer</h2>
                            <p>We have a list of frequently asked questions about us</p>
                        </div>
                    </div>
                </div>
                <div class="row cus-mar">
                    <div class="col-lg-6">
                        <div class="accordion" id="accordionLeft">
                            <div class="accordion-item">
                                <h6 class="accordion-header" id="headingLeftOne">
                                    <button class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseLeftOne"
                                        aria-expanded="false" aria-controls="collapseLeftOne">
                                        How to send money online?
                                    </button>
                                </h6>
                                <div id="collapseLeftOne" class="accordion-collapse collapse"
                                    aria-labelledby="headingLeftOne" data-bs-parent="#accordionLeft">
                                    <div class="accordion-body">
                                        <p>Get your other partiner's wallet to send the money in seconds.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h6 class="accordion-header" id="headingLeftTwo">
                                    <button class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseLeftTwo"
                                        aria-expanded="false" aria-controls="collapseLeftTwo">
                                        How much are money transfer fees?
                                    </button>
                                </h6>
                                <div id="collapseLeftTwo" class="accordion-collapse collapse"
                                    aria-labelledby="headingLeftTwo" data-bs-parent="#accordionLeft">
                                    <div class="accordion-body">
                                        <p>Up to 1,000,000 usd.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h6 class="accordion-header" id="headingLeftThree">
                                    <button class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseLeftThree"
                                        aria-expanded="false" aria-controls="collapseLeftThree">
                                        What is the fastest way to send money abroad?
                                    </button>
                                </h6>
                                <div id="collapseLeftThree" class="accordion-collapse collapse"
                                    aria-labelledby="headingLeftThree" data-bs-parent="#accordionLeft">
                                    <div class="accordion-body">
                                        <p>By suing the crypto wallet through DurraFx.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h6 class="accordion-header" id="headingLeftFive">
                                    <button class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseLeftFive"
                                        aria-expanded="false" aria-controls="collapseLeftFive">
                                        How does DurraFX protect your money?
                                    </button>
                                </h6>
                                <div id="collapseLeftFive" class="accordion-collapse collapse"
                                    aria-labelledby="headingLeftFive" data-bs-parent="#accordionLeft">
                                    <div class="accordion-body">
                                        <p>We are regulated and have our money sent through secure api.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="accordion" id="accordionRight">

                            <div class="accordion-item">
                                <h6 class="accordion-header" id="headingRightTwo">
                                    <button class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseRightTwo"
                                        aria-expanded="false" aria-controls="collapseRightTwo">
                                        How much money can I Receive?
                                    </button>
                                </h6>
                                <div id="collapseRightTwo" class="accordion-collapse collapse"
                                    aria-labelledby="headingRightTwo" data-bs-parent="#accordionRight">
                                    <div class="accordion-body">
                                        <p>Your customer can send you any amount as we provide wallets and have good relationships with banks.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h6 class="accordion-header" id="headingRightThree">
                                    <button class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseRightThree"
                                        aria-expanded="false" aria-controls="collapseRightThree">
                                        Which currency can I send?
                                    </button>
                                </h6>
                                <div id="collapseRightThree" class="accordion-collapse collapse"
                                    aria-labelledby="headingRightThree" data-bs-parent="#accordionRight">
                                    <div class="accordion-body">
                                        <p>All cuurencies supported in the world exhanged with either USD or USDT.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h6 class="accordion-header" id="headingRightFour">
                                    <button class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseRightFour"
                                        aria-expanded="false" aria-controls="collapseRightFour">
                                        Cancel transaction
                                    </button>
                                </h6>
                                <div id="collapseRightFour" class="accordion-collapse collapse"
                                    aria-labelledby="headingRightFour" data-bs-parent="#accordionRight">
                                    <div class="accordion-body">
                                        <p>Because of the nature of USDT, make sure you have the correct account before initiating the transaction.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h6 class="accordion-header" id="headingRightFive">
                                    <button class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseRightFive"
                                        aria-expanded="false" aria-controls="collapseRightFive">
                                        Can I send multiple payments?
                                    </button>
                                </h6>
                                <div id="collapseRightFive" class="accordion-collapse collapse"
                                    aria-labelledby="headingRightFive" data-bs-parent="#accordionRight">
                                    <div class="accordion-body">
                                        <p>You can send to various wallets and lients at once.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- FAQs In end -->

    <!-- Footer Area Start -->
    <footer class="footer-section">
        <div class="container">

            <div class="footer-area pt-120">
                <div class="footer-top">
                    <div class="row align-items-center">
                        <div class="col-sm-6 d-flex justify-content-center justify-content-sm-start">
                            <div class="menu-item">
                                <ul class="footer-link d-flex align-items-center">
                                    <li><a href="about-us.html">About Us</a></li>
                                    <li><a href="help-center.html">Support</a></li>
                                    <li><a href="fees.html">Fees</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="social-link d-flex justify-content-sm-end justify-content-center align-items-center">
<!--                                <a href="javascript:void(0)"><img src="assets/images/icon/facebook.png" alt="icon"></a>-->
<!--                                <a href="javascript:void(0)"><img src="assets/images/icon/linkedin.png" alt="icon"></a>-->
<!--                                <a href="javascript:void(0)"><img src="assets/images/icon/instagram.png" alt="icon"></a>-->
<!--                                <a href="javascript:void(0)"><img src="assets/images/icon/twitter.png" alt="icon"></a>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-md-6 col-sm-8 d-flex justify-content-center justify-content-sm-start order-sm-0 order-1">
                            <div class="copyright text-center text-sm-start">
                                <p> Copyright © 2024 <a href="index.html">DurraFX.</a> All Rights Reserved.</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-4">
                            <div class="menu-item">
                                <ul class="footer-link d-flex justify-content-sm-end justify-content-center align-items-center">
                                    <li><a href="terms-conditions.html">Terms</a></li>
                                    <li><a href="privacy-policy.html">Privacy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- Footer Area End -->

  </div>

</template>

<style scoped>
.header-section {
    margin-left: 0px !important;
    width: 100% !important;
    border-bottom: none;
    box-shadow: 0px 1px 4px grey;
}
.header-section .navbar {
    padding: 8px 15px;
}

</style>

<script setup>

import '../../public/assets/css/bootstrap.min.css'
import '../../public/assets/css/fontawesome.min.css'
import '../../public/assets/css/jquery-ui.css'
// import '../../public/assets/css/nice-select.css'
import '../../public/assets/css/magnific-popup.css'
import '../../public/assets/css/slick.css'
import '../../public/assets/css/arafat-font.css'
import '../../public/assets/css/animate.css'
import '../../public/assets/css/style.css'

import '../../public/assets/js/home_main.js'

</script>

<script>
import core_function from "@/services/core_function";
export default {
  name: "HomePage",
  data() {
    return {
        currencies: [],
        form: {
          "selectedCurrency": {},
          "currencyToSend": 1000,
          "fees": 10,
        }
    }
  },
  components: {

  },
  mounted() {
    this.getCurrencies()
  },
  created() {

  },
  methods: {
    async getCurrencies(){
        try{
          let response = await core_function.loadCurrencies(1, 1000, '')
          this.currencies = response.data['results']
        }catch(err){
          console.log(err)
        }
    }
  },

}
</script>