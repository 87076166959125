<template>
  <div>
    <sidebar />
    <div>
        <router-view />
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar/Sidebar.vue";

import '../../public/assets/dashboard/css/style.css'

export default {
  name: "admin-layout",
  components: {
    Sidebar,
  },
};
</script>